import React, { Component } from "react"
import axios from "axios"
import { Link } from "gatsby"
import { Form } from "reactstrap"
import apiRequest from "../../hooks/HttpRequest"
import { navigate } from "gatsby"
import { saveUserOnSessionStorage, isLoggedIn } from "../../services/auth"
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

export default class RegisterSection extends Component {
  constructor(props) {
    super(props)
    this.state = {
      membersArray: [],
      MemberID: 0,
      FName: "",
      LName: "",
      MobileNo: "",
      PrimaryEmail: "",
      SecondaryEmail: "",
      Password: "",
      PrimaryPhone: "",
      SecondaryPhone: "",
      SpouseName: "",
      SpouseEmail: "",
      FamilyMemberCount: "",
      MembershipType: "",
      IsOnlineAccount: false,

    }
  }
  insertMember = (e) => {
    e.preventDefault()
    const formData = new FormData()
    formData.append("FName", this.state.FName)
    formData.append("LName", this.state.LName)
    formData.append("PrimaryPhone", this.state.PrimaryPhone)
    formData.append("SecondaryPhone", this.state.SecondaryPhone)
    formData.append("SecondaryEmail", this.state.SecondaryEmail)
    formData.append("PrimaryEmail", this.state.PrimaryEmail)
    formData.append("SpouseName", this.state.SpouseName)
    formData.append("SpouseEmail", this.state.SpouseEmail)
    formData.append("FamilyMemberCount", this.state.FamilyMemberCount)
    formData.append("Password", this.state.Password)
    formData.append("MembershipType", this.state.MembershipType.split('-')[1])
    formData.append("MembershipFees", this.state.MembershipType.split('-')[0])
    formData.append("IsOnlineAccount", this.state.IsOnlineAccount)
    formData.append("IsAdmin", false)
    formData.append("IsCommitteeMember", false)
    formData.append("PhotoURL", "")
    formData.append("CreatedBy", 'registration')
    formData.append("UpdatedBy", '')

    // // Display the key/value pairs
    // for (var pair of formData.entries()) {
    //  // console.log(pair[0] + ', ' + pair[1]);
    // }
    // return;
    return axios
      .post(
        apiRequest.baseURL + "/Members/InsertMember",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          toast.success("Member Registered!!, Logging in..")
          saveUserOnSessionStorage(response.data.User)

          this.setState({
            MemberID: 0,
            FName: "",
            LName: "",
            MobileNo: "",
            PrimayEmail: "",
            SecondaryEmail: "",
            Password: "",
            PrimaryPhone: "",
            SecondaryPhone: "",
            SpouseName: "",
            SpouseEmail: "",
            FamilyMemberCount: 0,
            MembershipType: "",
            IsOnlineAccount: false,
          }, () => {
            navigate(`/members/profile`)
          })
        }
        else {
          if (response.data.Message.indexOf("UNIQUE KEY") > -1) {
            toast.error("Primary Email already Exists!");
          }
          else {
            toast.error(response.data.Message)
          }
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  }


  //handles form inputs change events
  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  render() {
    if (isLoggedIn()) {
      navigate(`/members/profile`)
    }
    return (
      <>
        <div className="page-title-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Sign Up</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Sign Up</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="signup-section ptb-100">
          <div className="container">
            <div className="signup-form">
              <h3>Register as Member</h3>
              <p>Please furnish below details</p>
              <Form onSubmit={this.insertMember}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        name="FName"
                        required
                        type="text"
                        className="form-control"
                        placeholder="First Name"
                        onChange={this.handleChange}
                        value={this.state.FName}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        type="text"
                        name="LName"
                        className="form-control"
                        placeholder="Last Name"
                        value={this.state.LName}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Primary Email (User Name)"
                        required
                        type="email"
                        name="PrimaryEmail"
                        className="form-control"
                        value={this.state.PrimaryEmail}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Primary Phone"
                        required
                        type="tel"
                        name="PrimaryPhone"
                        className="form-control"
                        value={this.state.PrimaryPhone}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Spouse Name"
                        type="text"
                        name="SpouseName"
                        className="form-control"
                        value={this.state.SpouseName}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Spouse Email"
                        type="text"
                        name="SpouseEmail"
                        className="form-control"
                        value={this.state.SpouseEmail}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Secondary Email"
                        type="text"
                        name="SecondaryEmail"
                        className="form-control"
                        value={this.state.SecondaryEmail}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Secondary Phone"
                        type="text"
                        name="SecondaryPhone"
                        className="form-control"
                        value={this.state.SecondaryPhone}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <input
                        autoComplete="off"
                        placeholder="Family Members Count"
                        type="zip"
                        name="FamilyMemberCount"
                        pattern="[0-9]{1}"
                        className="form-control"
                        value={this.state.FamilyMemberCount}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <select
                        placeholder="Membership Type"
                        required
                        type="zip"
                        name="MembershipType"
                        className="form-control"
                        onChange={this.handleChange}
                      >
                        <option value="">Select Membership</option>
                        <option value="15-Single Member">Single Member</option>
                        <option value="30-Family Member">Family Member</option>
                      </select>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label htmlFor="IsOnlineAccount">Want Online Account?</label><br />
                      <input
                        type="checkbox"
                        name="IsOnlineAccount"
                        className="form-control-checkbox"
                        value={this.state.IsOnlineAccount}
                        defaultChecked={this.state.IsOnlineAccount}
                        onChange={this.handleChange}
                      />
                    </div>
                  </div>
                  {
                    this.state.IsOnlineAccount === true &&
                    <div className="col-lg-6" >
                      <div className="form-group">
                        <input
                          placeholder="Password"
                          required
                          type="password"
                          name="Password"
                          className="form-control"
                          value={this.state.Password}
                          onChange={this.handleChange}
                        />
                      </div>
                    </div>
                  }


                  <div className="col-lg-12">
                    <div className="send-btn">
                      <button onClick={this.checkLogin} className="default-btn">
                        Signup Now
                        <span />
                      </button>
                    </div>
                    <br />
                    <span>
                      Already a registered user?{" "}
                      <Link to="/login">Login!</Link>
                    </span>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </>
    )
  }
}
