import React, { Component } from "react"
import DataTable from "react-data-table-component"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"
//import Moment from "moment";

import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"
import Sidebar from "../../layouts/Sidebar"
let gridColumns
export default class Gallery extends Component {
  constructor(props) {
    super(props)

    this.state = {
      arrayGalleryItems: [],
      fromAddOpen: false,
      formEditOpen: false,
      formAction: "Add",
      previewImage: "",
      alertMessage: "",
      LiveImageURL: null,

      //CreatedBy: sessionStorage.getItem("UserName"),
      // //UpdatedBy: sessionStorage.getItem("UserName"),

      EventID: 0,
      ImageURLs: [],
      ImageTitle: "",
      Description: "",
      AddedOn: "",
      //CreatedBy: "",
      //UpdatedBy: "",
    }
  }

  componentDidMount() {
    gridColumns = [
      {
        name: "Event Name",
        selector: "EName",
        sortable: true,
      },
      {
        name: "Description",
        selector: "Description",
        cell: (row) => <span>{row.Description.substring(0, 50) + "..."}</span>,
      },
      {
        name: "Date",
        selector: "EDate",
      },
      {
        name: "Time",
        selector: "ETime",
      },
      {
        name: "Location",
        selector: "Location",
      },
      {
        name: "No Of Images",
        selector: "NoOfItems",
      },
      {
        name: "Action",
        cell: (row) => (
          <button
            className="btn btn-dark"
            onClick={() => this.loadEditForm(row.EventID)}
            title={
              'Edit "' + row.FName + " " + row.MName + " " + row.LName + '"'
            }
          >
            Edit
          </button>
        ),
      },
    ]

    this.loadGrid()
  }

  loadGrid() {
    axios
      .get(
        apiRequest.baseURL + "/GalleryItems/GetAllGalleryItems",
        apiRequest.jsonHeader
      )
      .then((response) => {
        this.setState({
          arrayGalleryItems: response.data.GalleryItems,
        })
      })
      .catch((e) => {
        // console.log(e)
        toast.error(e)
      })
  }

  //handles form inputs change events
  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  //handles form file input change event
  onFileChange = (e) => {
    this.setState(
      {
        ImageURLs: e.target.files,
        //previewImage: URL.createObjectURL(e.target.files[0]),
      },
      // () => console.log(this.state.ImageURLs)
    )
  }

  showHideFormAdd = (e) => {
    this.setState({
      formAddOpen: !this.state.formAddOpen,
      EventID: 0,
      EName: "",
      Description: "",
      ImageURL: "",
      EDate: "",
      Location: "",
      ETime: "",
    })
  }

  showHideFormEdit = (e) => {
    this.setState({ formEditOpen: !this.state.formEditOpen })
  }

  loadEditForm(paramEventID) {
    axios
      .get(
        apiRequest.baseURL + "Events/GetEvent/" + paramEventID,
        apiRequest.jsonHeader
      )
      .then((response) => {
        this.setState({
          EventID: response.data.GalleryItems.EventID,
          ImageURL: response.data.GalleryItems.ImageURL,
          ImageTitle: response.data.GalleryItems.ImageTitle,
          Description: response.data.GalleryItems.Description,
          AddedOn: response.data.GalleryItems.AddedOn,
          //CreatedBy: response.data.GalleryItems.CreatedBy,
          //UpdatedBy: response.data.GalleryItems.UpdatedBy,

          previewImage: response.data.Event.ImageURL,

          formAction: "Edit",
          formEditOpen: !this.state.formEditOpen,
        })
      })
      .catch((e) => {
        toast.error(e)
        // console.log(e)
      })
  }

  insertGalleryItems = (e) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("EventID", 0)
    formData.append("EventID", this.state.EventID)
    formData.append("ImageURLs", this.state.ImageURLs)
    formData.append("ImageTitle", this.state.ImageTitle)
    formData.append("Description", this.state.Description)
    formData.append("AddedOn", this.state.AddedOn)
    formData.append("CreatedBy", this.state.CreatedBy)
    formData.append("UpdatedBy", this.state.UpdatedBy)

    return axios
      .post(
        apiRequest.baseURL + "/Events/InsertEvent",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage:
              "Event " +
              this.state.FirstName +
              " " +
              this.state.MiddleName +
              " " +
              this.state.LastName +
              " Added !!",
            EventID: 0,
            EName: "",
            Description: "",
            ImageURL: "",
            previewImage: "",
            EDate: "",
            Location: "",
            ETime: "",
          })
          toast.success(this.state.alertMessage)
          this.showHideFormAdd()
          this.loadGrid()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  updateGalleryItems = (e) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("EventID", 0)
    formData.append("EName", this.state.EName)
    formData.append("Description", this.state.Description)
    formData.append("ImageURL", this.state.ImageURL)
    formData.append("Location", this.state.Location)
    formData.append("EDate", this.state.EDate)
    formData.append("ETime", this.state.EDate)
    formData.append("CreatedBy", this.state.CreatedBy)
    formData.append("UpdatedBy", this.state.UpdatedBy)

    return axios
      .put(
        apiRequest.baseURL + "/Events/UpdateEvent/" + this.state.EventID,
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage:
              "Event " +
              this.state.FirstName +
              " " +
              this.state.MiddleName +
              " " +
              this.state.LastName +
              " Updated !!",
            EventID: 0,
            EName: "",
            Description: "",
            ImageURL: "",
            previewImage: "",
            EDate: "",
            Location: "",
            ETime: "",
            formEditOpen: !this.state.formEditOpen,
          })
          toast.success(this.state.alertMessage)
          this.loadGrid()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  deleteEvent = () => {
    return axios
      .delete(
        apiRequest.baseURL + "/Events/DeleteEvent/" + this.state.EventID,
        apiRequest.jsonHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage:
              "Event " +
              this.state.FirstName +
              " " +
              this.state.MiddleName +
              " " +
              this.state.LastName +
              " Deleted !!",
            EventID: 0,
            EName: "",
            Description: "",
            ImageURL: "",
            EDate: "",
            Location: "",
            ETime: "",
          })
          toast.info(this.state.alertMessage)
          this.loadGrid()
          this.showHideFormEdit()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        // console.log(e)
      })
  }

  render() {
    return (
      <>
        {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (

          <Sidebar></Sidebar>

        ) : null}
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-12">
              <div className="widget-content widget-content-area br-6">
                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* List Category Grid Starts */}
                <div className="row">
                  <div className="col-md-3">
                    <h3>Events</h3>
                  </div>
                  <div className="col-md-5"></div>
                  <div className="col-md-4 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={this.showHideFormAdd}
                    >
                      Add Event
                  </button>
                  </div>
                </div>
                <ToastContainer />
                <div id="grid">
                  <DataTable
                    columns={gridColumns}
                    pagination
                    highlightOnHover
                    responsive
                    data={this.state.arrayGalleryItems}
                  />
                </div>
                {/* List Category Grid Ends */}

                {/* ----------------------------------------------------------------------------------------------------- */}

                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* Add From Starts */}
                <Modal isOpen={this.state.formAddOpen} size="xl">
                  <Form onSubmit={this.insertGalleryItems}>
                    <ModalHeader>Gallery Image : Add</ModalHeader>
                    <ModalBody>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="ImageTitle">Image Title</label>
                                <input
                                  required
                                  type="text"
                                  name="ImageTitle"
                                  className="form-control"
                                  value={this.state.ImageTitle}
                                  onChange={this.handleChange}
                                />{" "}
                              </div>{" "}
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <textarea
                                  rows="10"
                                  required
                                  name="Description"
                                  className="form-control"
                                  onChange={this.handleChange}
                                >
                                  {this.state.Description}
                                </textarea>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="ImageURLs">Choose Image</label>
                          <br />
                          <input
                            type="file"
                            className="form-control-file"
                            name="ImageURLs"
                            id="ImageURLs"
                            onChange={this.onFileChange}
                            style={{ display: "none" }}
                            multiple="true"
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.showHideFormAdd}>
                        Cancel
                    </Button>
                      <Button type="submit" color="primary">
                        Submit
                    </Button>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Add From Ends */}
                {/* ----------------------------------------------------------------------------------------------------- */}

                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* Edit From Starts */}
                <Modal isOpen={this.state.formEditOpen} size="xl">
                  <Form onSubmit={this.updateGalleryItems}>
                    <ModalHeader>Event : Edit</ModalHeader>
                    <ModalBody>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="ImageTitle">Image Title</label>
                                <input
                                  required
                                  type="text"
                                  name="ImageTitle"
                                  className="form-control"
                                  value={this.state.ImageTitle}
                                  onChange={this.handleChange}
                                />{" "}
                              </div>{" "}
                            </div>
                            <div className="col-md-12">
                              <div className="form-group">
                                <label htmlFor="Description">Description</label>
                                <textarea
                                  rows="10"
                                  required
                                  name="Description"
                                  className="form-control"
                                  onChange={this.handleChange}
                                >
                                  {this.state.Description}
                                </textarea>
                              </div>{" "}
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <label htmlFor="ImageURLs">Choose Image</label>
                          <br />
                          <label htmlFor="ImageURLs">
                            {this.state.previewImage !== "" ? (
                              <img
                                src={this.state.previewImage}
                                alt="Choose Event "
                                width="500"
                                height="350"
                              />
                            ) : (
                              <img src={"uploads/Noimage.jpg"} alt="No Event " />
                            )}
                          </label>
                          <input
                            type="file"
                            className="form-control-file"
                            name="ImageURLs"
                            id="ImageURLs"
                            onChange={this.onFileChange}
                            style={{ display: "none" }}
                            multiple="true"
                          />
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="danger"
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteEvent()
                          }
                        }}
                      >
                        Delete
                    </Button>
                      <Button color="secondary" onClick={this.showHideFormEdit}>
                        Cancel
                    </Button>
                      <Button type="submit" color="primary">
                        Submit
                    </Button>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Edit From Ends */}
                {/* ----------------------------------------------------------------------------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
