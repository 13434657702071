import React, { Component } from "react"
import DataTable from "react-data-table-component"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"

import {
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"
import Sidebar from "../../layouts/Sidebar"
let gridColumns
export default class FoodItem extends Component {
    constructor(props) {
        super(props)

        this.state = {
            foodItemsArray: [],
            fromAddOpen: false,
            formEditOpen: false,

            ItemID: 0,
            ItemName: "",
            ItemDescription: "",
            ItemMemberPrice: 0,
            ItemGuestPrice: 0,

            CreatedBy: '',
            UpdatedBy: '',
            // CreatedBy: sessionStorage.getItem("UserName"),
            // UpdatedBy: sessionStorage.getItem("UserName"),
        }
    }

    componentDidMount() {
        document.title = "Food Items Management : KyTS "
        gridColumns = [
            {
                name: "Item Name",
                selector: "ItemName",
                sortable: true,
            },
            {
                name: "Description",
                selector: "Description",
                cell: (row) => <span>{row.ItemDescription.substring(0, 50) + "..."}</span>,
            },
            {
                name: "Member Price",
                selector: "ItemMemberPrice",
                sortable: true,
            },
            {
                name: "Guest Price",
                selector: "ItemGuestPrice",
                sortable: true,
            },
            {
                name: "Action",
                cell: (row) => (
                    <button
                        className="btn btn-dark"
                        onClick={() => this.loadEditForm(row.ItemID)}
                        title={'Edit "' + row.ItemName + '"'}
                    >
                        Edit
                    </button>
                ),
            },
        ]

        this.loadGrid()
    }

    loadGrid() {
        axios
            .get(apiRequest.baseURL + "/FoodItems/GetAllFoodItems", apiRequest.jsonHeader)
            .then((response) => {
                this.setState({
                    foodItemsArray: response.data.FoodItems,
                })
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    //handles form inputs changebanners
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    //handles form file input change Food Item
    onFileChange = (e) => {
        this.setState({
            ImageURL: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
        })
    }

    showHideFormAdd = (e) => {
        this.setState({
            formAddOpen: !this.state.formAddOpen,
            ItemID: 0,
            ItemName: "",
            ItemDescription: "",
            ItemMemberPrice: 0,
            ItemGuestPrice: 0,

            CreatedBy: '',
            UpdatedBy: '',
        })
    }

    showHideFormEdit = (e) => {
        this.setState({ formEditOpen: !this.state.formEditOpen })
    }

    loadEditForm(pramItemID) {
        axios
            .get(
                apiRequest.baseURL + "FoodItems/GetFoodItem/" + pramItemID,
                apiRequest.jsonHeader
            )
            .then((response) => {
                this.setState({
                    ItemID: response.data.FoodItem.ItemID,
                    ItemName: response.data.FoodItem.ItemName,
                    ItemDescription: response.data.FoodItem.ItemDescription,
                    ItemMemberPrice: response.data.FoodItem.ItemMemberPrice,
                    ItemGuestPrice: response.data.FoodItem.ItemGuestPrice,

                    formAction: "Edit",
                    formEditOpen: !this.state.formEditOpen,
                })
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    insertFoodItem = (e) => {
        e.preventDefault()

        const jsonPayload = {
            "ItemID": this.state.ItemID,
            "ItemName": this.state.ItemName,
            "ItemDescription": this.state.ItemDescription,
            "ItemMemberPrice": this.state.ItemMemberPrice,
            "ItemGuestPrice": this.state.ItemGuestPrice,
            "CreatedBy": this.state.CreatedBy,
            "UpdatedBy": this.state.UpdatedBy
        }


        return axios
            .post(
                apiRequest.baseURL + "/FoodItems/InsertFoodItem",
                jsonPayload,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Food Item " + this.state.ItemName + " Added !!",
                        ItemID: 0,
                        ItemName: "",
                        ItemDescription: "",
                        ItemMemberPrice: 0,
                        ItemGuestPrice: 0,

                        CreatedBy: '',
                        UpdatedBy: '',
                    })
                    toast.success(this.state.alertMessage)
                    this.showHideFormAdd()
                    this.loadGrid()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    updateFoodItem = (e) => {
        e.preventDefault()

        const jsonPayload = {
            "ItemID": this.state.ItemID,
            "ItemName": this.state.ItemName,
            "ItemDescription": this.state.ItemDescription,
            "ItemMemberPrice": this.state.ItemMemberPrice,
            "ItemGuestPrice": this.state.ItemGuestPrice,
            "CreatedBy": this.state.CreatedBy,
            "UpdatedBy": this.state.UpdatedBy
        }


        return axios
            .put(
                apiRequest.baseURL + "/FoodItems/UpdateFoodItem/" + this.state.ItemID,
                jsonPayload,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Food Item " + this.state.ItemName + " Updated !!",
                        ItemID: 0,
                        ItemName: "",
                        ItemDescription: "",
                        ItemMemberPrice: 0,
                        ItemGuestPrice: 0,

                        CreatedBy: '',
                        UpdatedBy: '',
                        formEditOpen: !this.state.formEditOpen,
                    })
                    toast.success(this.state.alertMessage)
                    this.loadGrid()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    deleteFoodItem = () => {
        return axios
            .delete(
                apiRequest.baseURL + "/FoodItems/DeleteFoodItem/" + this.state.ItemID,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Food Item " + this.state.ItemName + " Deleted !!",
                        ItemID: 0,
                        ItemName: "",
                        ItemDescription: "",
                        ItemMemberPrice: 0,
                        ItemGuestPrice: 0,

                        CreatedBy: '',
                        UpdatedBy: '',
                    })
                    toast.info(this.state.alertMessage)
                    this.loadGrid()
                    this.showHideFormEdit()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => { })
    }

    render() {
        return (
            <>
                {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (
                    <Sidebar></Sidebar>
                ) : null}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="widget-content widget-content-area br-6">
                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* List Category Grid Starts */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <h3>Food Items</h3>
                                    </div>
                                    <div className="col-md-5"></div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.showHideFormAdd}
                                        >
                                            Add Food Item
                                        </button>
                                    </div>
                                </div>
                                <ToastContainer />
                                <div id="grid">
                                    <DataTable
                                        columns={gridColumns}
                                        pagination
                                        highlightOnHover
                                        responsive
                                        data={this.state.foodItemsArray}
                                    />
                                </div>
                                {/* List Category Grid Ends */}

                                {/* ----------------------------------------------------------------------------------------------------- */}

                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* Add From Starts */}
                                <Modal isOpen={this.state.formAddOpen} >
                                    <Form onSubmit={this.insertFoodItem}>
                                        <ModalHeader>Food Item : Add</ModalHeader>
                                        <ModalBody >
                                            <div className="col-md-12 mt-4">
                                                <div className="row">
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemName">ItemName</label><input autocomplete="off" name="ItemName" required type="text" className="form-control" value={this.state.ItemName} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemDescription">ItemDescription</label><input autocomplete="off" name="ItemDescription" required type="text" className="form-control" value={this.state.ItemDescription} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemMemberPrice">ItemMemberPrice</label><input autocomplete="off" name="ItemMemberPrice" required type="text" className="form-control" value={this.state.ItemMemberPrice} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemGuestPrice">ItemGuestPrice</label><input autocomplete="off" name="ItemGuestPrice" required type="text" className="form-control" value={this.state.ItemGuestPrice} onChange={this.handleChange} /></div></div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={this.showHideFormAdd}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" color="primary">
                                                Submit
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>

                                {/* Add From Ends */}
                                {/* ----------------------------------------------------------------------------------------------------- */}

                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* Edit From Starts */}
                                <Modal isOpen={this.state.formEditOpen} >
                                    <Form onSubmit={this.updateFoodItem}>
                                        <ModalHeader>Food Item : Edit</ModalHeader>
                                        <ModalBody>
                                            <div className="col-md-12 mt-4">
                                                <div className="row">
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemName">ItemName</label><input autocomplete="off" name="ItemName" required type="text" className="form-control" value={this.state.ItemName} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemDescription">ItemDescription</label><input autocomplete="off" name="ItemDescription" required type="text" className="form-control" value={this.state.ItemDescription} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemMemberPrice">ItemMemberPrice</label><input autocomplete="off" name="ItemMemberPrice" required type="text" className="form-control" value={this.state.ItemMemberPrice} onChange={this.handleChange} /></div></div>
                                                    <div className="col-lg-6"><div className="form-group"><label htmlFor="ItemGuestPrice">ItemGuestPrice</label><input autocomplete="off" name="ItemGuestPrice" required type="text" className="form-control" value={this.state.ItemGuestPrice} onChange={this.handleChange} /></div></div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    if (window.confirm("Delete the item?")) {
                                                        this.deleteFoodItem()
                                                    }
                                                }}
                                            >
                                                Delete
                          </Button>
                                            <Button color="secondary" onClick={this.showHideFormEdit}>
                                                Cancel
                          </Button>
                                            <Button type="submit" color="primary">
                                                Submit
                          </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>

                                {/* Edit From Ends */}
                                {/* ----------------------------------------------------------------------------------------------------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}