import React, { Component } from "react"
import DataTable from "react-data-table-component"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"

import {
    Button,
    Form,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"
import Sidebar from "../../layouts/Sidebar"
let gridColumns

export default class Sponsor extends Component {
    constructor(props) {
        super(props)

        this.state = {
            sponsorsArray: [],
            fromAddOpen: false,
            formEditOpen: false,
            formAction: "Add",
            previewImage: "",
            alertMessage: "",
            LiveImageURL: null,

            SponsorID: 0,
            SponserName: "",
            Description: "",
            ImageURL: "",
            WebsiteURL: "",

            bannerEventsArray: [],
            //CreatedBy: sessionStorage.getItem("UserName"),
            //UpdatedBy: sessionStorage.getItem("UserName"),
        }
    }

    componentDidMount() {
        document.title = "Sponsors Management : KyTS "
        gridColumns = [
            {
                name: "Sponsor Name",
                selector: "SponsorName",
                sortable: true,
            },
            {
                name: "Description",
                selector: "Description",
                cell: (row) => <span>{row.Description.substring(0, 50) + "..."}</span>,
            },
            {
                name: "Action",
                cell: (row) => (
                    <button
                        className="btn btn-dark"
                        onClick={() => this.loadEditForm(row.SponsorID)}
                        title={'Edit "' + row.SponsorName + '"'}
                    >
                        Edit
                    </button>
                ),
            },
        ]

        this.loadGrid()
    }

    loadGrid() {
        axios
            .get(apiRequest.baseURL + "/Sponsors/GetAllSponsors", apiRequest.jsonHeader)
            .then((response) => {
                this.setState({
                    sponsorsArray: response.data.Sponsors,
                })
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    //handles form inputs changebanners
    handleChange = (e) => {
        if (e.target.type === "checkbox") {
            this.setState({ [e.target.name]: e.target.checked ? true : false })
        } else {
            this.setState({ [e.target.name]: e.target.value })
        }
    }

    //handles form file input change banner
    onFileChange = (e) => {
        this.setState({
            ImageURL: e.target.files[0],
            previewImage: URL.createObjectURL(e.target.files[0]),
        })
    }

    showHideFormAdd = (e) => {
        this.setState({
            formAddOpen: !this.state.formAddOpen,
            SponsorID: 0,
            SponsorName: "",
            Description: "",
            ImageURL: "",
            previewImage: "",
        })
    }

    showHideFormEdit = (e) => {
        this.setState({ formEditOpen: !this.state.formEditOpen })
    }

    loadEditForm(paramSponsorID) {
        axios
            .get(
                apiRequest.baseURL + "Sponsors/GetSponsor/" + paramSponsorID,
                apiRequest.jsonHeader
            )
            .then((response) => {
                // console.log(response.data);
                this.setState({
                    SponsorID: response.data.Sponsor.SponsorID,
                    SponsorName: response.data.Sponsor.SponsorName,
                    Description: response.data.Sponsor.Description,
                    ImageURL: response.data.Sponsor.ImageURL,
                    previewImage: response.data.Sponsor.ImageURL,
                    WebsiteURL: response.data.Sponsor.WebsiteURL,
                    formAction: "Edit",
                    formEditOpen: !this.state.formEditOpen,
                })
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    insertSponsor = (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append("SponsorID", 0)
        formData.append("SponsorName", this.state.SponsorName)
        formData.append("Description", this.state.Description)
        formData.append("ImageURL", this.state.ImageURL)
        formData.append("WebsiteURL", this.state.WebsiteURL)
        formData.append("CreatedBy", this.state.CreatedBy)
        formData.append("UpdatedBy", this.state.UpdatedBy)

        return axios
            .post(
                apiRequest.baseURL + "/Sponsors/InsertSponsor",
                formData,
                apiRequest.multipartFormHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Sponsor " + this.state.SponsorName + " Added !!",
                        SponsorID: 0,
                        SponsorName: "",
                        Description: "",
                        ImageURL: "",
                        previewImage: "",
                    })
                    toast.success(this.state.alertMessage)
                    this.showHideFormAdd()
                    this.loadGrid()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    updateSponsor = (e) => {
        e.preventDefault()

        const formData = new FormData()

        formData.append("SponsorID", this.state.SponsorID)
        formData.append("SponsorName", this.state.SponsorName)
        formData.append("Description", this.state.Description)
        formData.append("ImageURL", this.state.ImageURL)
        formData.append("WebsiteURL", this.state.WebsiteURL)
        formData.append("CreatedBy", this.state.CreatedBy)
        formData.append("UpdatedBy", this.state.UpdatedBy)

        return axios
            .put(
                apiRequest.baseURL + "/Sponsors/UpdateSponsor/" + this.state.SponsorID,
                formData,
                apiRequest.multipartFormHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Sponsor " + this.state.SponsorName + " Updated !!",
                        SponsorID: 0,
                        SponsorName: "",
                        Description: "",
                        ImageURL: "",
                        previewImage: "",
                        formEditOpen: !this.state.formEditOpen,
                    })
                    toast.success(this.state.alertMessage)
                    this.loadGrid()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => {
                toast.error(e)
            })
    }

    deleteSponsor = () => {
        return axios
            .delete(
                apiRequest.baseURL + "/Sponsors/DeleteSponsor/" + this.state.SponsorID,
                apiRequest.jsonHeader
            )
            .then((response) => {
                if (response.data.Status === "Success") {
                    this.setState({
                        alertMessage: "Sponsor " + this.state.SponsorName + " Deleted !!",
                        SponsorID: 0,
                        SponsorName: "",
                        Description: "",
                        previewImage: "",
                        ImageURL: "",
                    })
                    toast.info(this.state.alertMessage)
                    this.loadGrid()
                    this.showHideFormEdit()
                } else {
                    toast.error(response.data.Message)
                }
            })
            .catch((e) => { })
    }

    render() {
        return (
            <>
                {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (
                    <Sidebar></Sidebar>
                ) : null}
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-md-12">
                            <div className="widget-content widget-content-area br-6">
                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* List Category Grid Starts */}
                                <div className="row">
                                    <div className="col-md-3">
                                        <h3>Sponsors</h3>
                                    </div>
                                    <div className="col-md-5"></div>
                                    <div className="col-md-4 text-right">
                                        <button
                                            className="btn btn-primary"
                                            onClick={this.showHideFormAdd}
                                        >
                                            Add Sponsor
                                        </button>
                                    </div>
                                </div>
                                <ToastContainer />
                                <div id="grid">
                                    <DataTable
                                        columns={gridColumns}
                                        pagination
                                        highlightOnHover
                                        responsive
                                        data={this.state.sponsorsArray}
                                    />
                                </div>
                                {/* List Category Grid Ends */}

                                {/* ----------------------------------------------------------------------------------------------------- */}

                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* Add From Starts */}
                                <Modal isOpen={this.state.formAddOpen} size="xl">
                                    <Form onSubmit={this.insertSponsor}>
                                        <ModalHeader>Sponsor : Add</ModalHeader>
                                        <ModalBody>
                                            <div className="col-md-12 mt-4">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="SponsorName">Sponser Name</label>
                                                            <input
                                                                required
                                                                type="text"
                                                                name="SponsorName"
                                                                className="form-control"
                                                                value={this.state.SponsorName}
                                                                onChange={this.handleChange}
                                                            />{" "}
                                                        </div>{" "}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="WebsiteURL">Website URL</label>
                                                            <input
                                                                required
                                                                type="text"
                                                                name="WebsiteURL"
                                                                className="form-control"
                                                                value={this.state.WebsiteURL}
                                                                onChange={this.handleChange}
                                                            />{" "}
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Description">Description</label>
                                                            <textarea
                                                                rows="10"
                                                                required
                                                                name="Description"
                                                                className="form-control"
                                                                onChange={this.handleChange}
                                                            >
                                                                {this.state.Description}
                                                            </textarea>
                                                        </div>{" "}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {" "}
                                                        <div className="form-group">
                                                            <label htmlFor="ImageURL">Choose Image</label>
                                                            <br />
                                                            <label htmlFor="ImageURL">
                                                                {this.state.previewImage !== "" ? (
                                                                    <img
                                                                        src={this.state.previewImage}
                                                                        alt="Choose Sponsor Logo"
                                                                        width="500"
                                                                        height="400"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            apiRequest.baseURL +
                                                                            "../images/no-image.png"
                                                                        }
                                                                        alt="No Sponsor Logo"
                                                                    />
                                                                )}
                                                            </label>
                                                            <input
                                                                type="file"
                                                                className="form-control-file"
                                                                name="ImageURL"
                                                                id="ImageURL"
                                                                onChange={this.onFileChange}
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button color="secondary" onClick={this.showHideFormAdd}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" color="primary">
                                                Submit
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>

                                {/* Add From Ends */}
                                {/* ----------------------------------------------------------------------------------------------------- */}

                                {/* ----------------------------------------------------------------------------------------------------- */}
                                {/* Edit From Starts */}
                                <Modal isOpen={this.state.formEditOpen} size="xl">
                                    <Form onSubmit={this.updateSponsor}>
                                        <ModalHeader>Sponsor : Edit</ModalHeader>
                                        <ModalBody>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="SponsorName">Sponsor Name</label>
                                                            <input
                                                                required
                                                                type="text"
                                                                name="SponsorName"
                                                                className="form-control"
                                                                value={this.state.SponsorName}
                                                                onChange={this.handleChange}
                                                            />{" "}
                                                        </div>{" "}
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="WebsiteURL">Website URL</label>
                                                            <input
                                                                required
                                                                type="text"
                                                                name="WebsiteURL"
                                                                className="form-control"
                                                                value={this.state.WebsiteURL}
                                                                onChange={this.handleChange}
                                                            />{" "}
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="form-group">
                                                            <label htmlFor="Description">Description</label>
                                                            <textarea
                                                                rows="10"
                                                                required
                                                                name="Description"
                                                                className="form-control"
                                                                onChange={this.handleChange}
                                                            >
                                                                {this.state.Description}
                                                            </textarea>
                                                        </div>{" "}
                                                    </div>
                                                    <div className="col-md-6">
                                                        {" "}
                                                        <div className="form-group">
                                                            <label htmlFor="ImageURL">Choose Logo</label>
                                                            <br />
                                                            <label htmlFor="ImageURL">
                                                                {this.state.previewImage !== "" ? (
                                                                    <img
                                                                        src={this.state.previewImage}
                                                                        alt="Choose Sponsor Logo"
                                                                        width="500"
                                                                        height="400"
                                                                    />
                                                                ) : (
                                                                    <img
                                                                        src={
                                                                            apiRequest.baseURL +
                                                                            "../images/no-image.png"
                                                                        }
                                                                        alt="No Sponsor Logo"
                                                                    />
                                                                )}
                                                            </label>
                                                            <input
                                                                type="file"
                                                                className="form-control-file"
                                                                name="ImageURL"
                                                                id="ImageURL"
                                                                onChange={this.onFileChange}
                                                                style={{ display: "none" }}
                                                            />
                                                        </div>{" "}
                                                    </div>
                                                </div>
                                            </div>
                                        </ModalBody>
                                        <ModalFooter>
                                            <Button
                                                color="danger"
                                                onClick={() => {
                                                    if (window.confirm("Delete the item?")) {
                                                        this.deleteSponsor()
                                                    }
                                                }}
                                            >
                                                Delete
                                            </Button>
                                            <Button color="secondary" onClick={this.showHideFormEdit}>
                                                Cancel
                                            </Button>
                                            <Button type="submit" color="primary">
                                                Submit
                                            </Button>
                                        </ModalFooter>
                                    </Form>
                                </Modal>

                                {/* Edit From Ends */}
                                {/* ----------------------------------------------------------------------------------------------------- */}
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }
}
