import React, { Component } from "react"
import DataTable from "react-data-table-component"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"

import {
  Button,
  Form,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"
import Sidebar from "../../layouts/Sidebar"
let gridColumns
export default class Banner extends Component {
  constructor(props) {
    super(props)

    this.state = {
      bannersArray: [],
      fromAddOpen: false,
      formEditOpen: false,
      formAction: "Add",
      previewImage: "",
      alertMessage: "",
      LiveImageURL: null,

      BannerID: 0,
      ImageTitle: "",
      Description: "",
      ImageURL: "",
      BannerEventID: 0,

      bannerEventsArray: [],
      //CreatedBy: sessionStorage.getItem("UserName"),
      //UpdatedBy: sessionStorage.getItem("UserName"),
    }
  }

  componentDidMount() {
    document.title = "Banners Management : KyTS "
    gridColumns = [
      {
        name: "Banner Name",
        selector: "ImageTitle",
        sortable: true,
      },
      {
        name: "Description",
        selector: "Description",
        cell: (row) => <span>{row.Description.substring(0, 50) + "..."}</span>,
      },
      {
        name: "Action",
        cell: (row) => (
          <button
            className="btn btn-dark"
            onClick={() => this.loadEditForm(row.BannerID)}
            title={'Edit "' + row.ImageTitle + '"'}
          >
            Edit
          </button>
        ),
      },
    ]

    this.loadGrid()
  }

  loadGrid() {
    axios
      .get(apiRequest.baseURL + "/Banners/GetAllBanners", apiRequest.jsonHeader)
      .then((response) => {
        this.setState({
          bannersArray: response.data.Banners,
        })
      })
      .catch((e) => {
        toast.error(e)
      })

    axios
      .get(apiRequest.baseURL + "/Events/GetAllEvents", apiRequest.jsonHeader)
      .then((response) => {
        this.setState({
          bannerEventsArray: response.data.Events,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  //handles form inputs changebanners
  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  //handles form file input change banner
  onFileChange = (e) => {
    this.setState({
      ImageURL: e.target.files[0],
      previewImage: URL.createObjectURL(e.target.files[0]),
    })
  }

  showHideFormAdd = (e) => {
    this.setState({
      formAddOpen: !this.state.formAddOpen,
      BannerID: 0,
      ImageTitle: "",
      Description: "",
      ImageURL: "",
      previewImage: "",
    })
  }

  showHideFormEdit = (e) => {
    this.setState({ formEditOpen: !this.state.formEditOpen })
  }

  loadEditForm(paramBannerID) {
    axios
      .get(
        apiRequest.baseURL + "Banners/GetBanner/" + paramBannerID,
        apiRequest.jsonHeader
      )
      .then((response) => {
        this.setState({
          BannerID: response.data.Banner.BannerID,
          ImageTitle: response.data.Banner.ImageTitle,
          Description: response.data.Banner.Description,
          ImageURL: response.data.Banner.ImageURL,
          previewImage: response.data.Banner.ImageURL,
          BannerEventID: response.data.Banner.EventID,
          formAction: "Edit",
          formEditOpen: !this.state.formEditOpen,
        })
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  insertBanner = (e) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("BannerID", 0)
    formData.append("EventID", this.state.BannerEventID)
    formData.append("ImageTitle", this.state.ImageTitle)
    formData.append("Description", this.state.Description)
    formData.append("ImageURL", this.state.ImageURL)
    formData.append("CreatedBy", this.state.CreatedBy)
    formData.append("UpdatedBy", this.state.UpdatedBy)

    return axios
      .post(
        apiRequest.baseURL + "/Banners/InsertBanner",
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage: "Banner " + this.state.ImageTitle + " Added !!",
            BannerID: 0,
            ImageTitle: "",
            Description: "",
            ImageURL: "",
            previewImage: "",
          })
          toast.success(this.state.alertMessage)
          this.showHideFormAdd()
          this.loadGrid()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  updateBanner = (e) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("BannerID", 0)
    formData.append("EventID", this.state.BannerEventID)
    formData.append("ImageTitle", this.state.ImageTitle)
    formData.append("Description", this.state.Description)
    formData.append("ImageURL", this.state.ImageURL)
    formData.append("CreatedBy", this.state.CreatedBy)
    formData.append("UpdatedBy", this.state.UpdatedBy)

    return axios
      .put(
        apiRequest.baseURL + "/Banners/UpdateBanner/" + this.state.BannerID,
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage: "Banner " + this.state.ImageTitle + " Updated !!",
            BannerID: 0,
            ImageTitle: "",
            Description: "",
            ImageURL: "",
            previewImage: "",
            formEditOpen: !this.state.formEditOpen,
          })
          toast.success(this.state.alertMessage)
          this.loadGrid()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }

  deleteBanner = () => {
    return axios
      .delete(
        apiRequest.baseURL + "/Banners/DeleteBanner/" + this.state.BannerID,
        apiRequest.jsonHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          this.setState({
            alertMessage: "Banner " + this.state.ImageTitle + " Deleted !!",
            BannerID: 0,
            ImageTitle: "",
            Description: "",
            previewImage: "",
            ImageURL: "",
          })
          toast.info(this.state.alertMessage)
          this.loadGrid()
          this.showHideFormEdit()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => { })
  }

  render() {
    return (
      <>
        {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (
          <Sidebar></Sidebar>
        ) : null}
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-12">
              <div className="widget-content widget-content-area br-6">
                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* List Category Grid Starts */}
                <div className="row">
                  <div className="col-md-3">
                    <h3>Banners</h3>
                  </div>
                  <div className="col-md-5"></div>
                  <div className="col-md-4 text-right">
                    <button
                      className="btn btn-primary"
                      onClick={this.showHideFormAdd}
                    >
                      Add Banner
                    </button>
                  </div>
                </div>
                <ToastContainer />
                <div id="grid">
                  <DataTable
                    columns={gridColumns}
                    pagination
                    highlightOnHover
                    responsive
                    data={this.state.bannersArray}
                  />
                </div>
                {/* List Category Grid Ends */}

                {/* ----------------------------------------------------------------------------------------------------- */}

                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* Add From Starts */}
                <Modal isOpen={this.state.formAddOpen} >
                  <Form onSubmit={this.insertBanner}>
                    <ModalHeader>Banner : Add</ModalHeader>
                    <ModalBody >
                      <div className="col-md-12 mt-4">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="ImageTitle">Banner Title</label>
                              <input
                                required
                                type="text"
                                name="ImageTitle"
                                className="form-control"
                                defaultValue={this.state.ImageTitle}
                                onChange={this.handleChange}
                              />{" "}
                            </div>{" "}
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="BannerEventID">Banner Event</label>
                              <select
                                name="BannerEventID"
                                className="form-control"
                                value={this.state.BannerEventID}
                                onChange={this.handleChange}
                              >
                                <option value=""></option>
                                {
                                  this.state.bannerEventsArray.map((item, index) => {
                                    return (
                                      <option value={item.EventID}>{item.EName}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>

                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="Description">Description</label>
                              <textarea
                                rows="10"
                                required
                                name="Description"
                                className="form-control"
                                onChange={this.handleChange}
                                defaultValue={this.state.Description}
                              >

                              </textarea>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="ImageURL">Choose Image</label>
                              <br />
                              <label htmlFor="ImageURL">
                                {this.state.previewImage !== "" ? (
                                  <img
                                    src={this.state.previewImage}
                                    alt="Choose Banner"
                                    width="500"
                                    height="400"
                                  />
                                ) : (
                                  <img
                                    src={
                                      apiRequest.baseURL +
                                      "../images/no-image.png"
                                    }
                                    alt="No Banner"
                                  />
                                )}
                              </label>
                              <input
                                type="file"
                                className="form-control-file"
                                name="ImageURL"
                                id="ImageURL"
                                onChange={this.onFileChange}
                                style={{ display: "none" }}
                              />
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button color="secondary" onClick={this.showHideFormAdd}>
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Add From Ends */}
                {/* ----------------------------------------------------------------------------------------------------- */}

                {/* ----------------------------------------------------------------------------------------------------- */}
                {/* Edit From Starts */}
                <Modal isOpen={this.state.formEditOpen} >
                  <Form onSubmit={this.updateBanner}>
                    <ModalHeader>Banner : Edit</ModalHeader>
                    <ModalBody>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="ImageTitle">Banner Title</label>
                              <input
                                required
                                type="text"
                                name="ImageTitle"
                                className="form-control"
                                defaultValue={this.state.ImageTitle}
                                onChange={this.handleChange}
                              />{" "}
                            </div>{" "}
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="BannerEventID">Banner Event</label>
                              <select
                                name="BannerEventID"
                                className="form-control"
                                onChange={this.handleChange}
                                value={this.state.BannerEventID}
                              >
                                <option value=""></option>
                                {
                                  this.state.bannerEventsArray.map((item, index) => {
                                    return (
                                      <option value={item.EventID}>{item.EName}</option>
                                    )
                                  })
                                }
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="Description">Description</label>
                              <textarea
                                rows="10"
                                required
                                name="Description"
                                className="form-control"
                                onChange={this.handleChange}
                                defaultValue={this.state.Description}
                              >
                              </textarea>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group">
                              <label htmlFor="ImageURL">Choose Image</label>
                              <br />
                              <label htmlFor="ImageURL">
                                {this.state.previewImage !== "" ? (
                                  <img
                                    src={this.state.previewImage}
                                    alt="Choose Banner"
                                    width="500"
                                    height="400"
                                  />
                                ) : (
                                  <img
                                    src={
                                      apiRequest.baseURL + "../images/no-image.png"
                                    }
                                    alt="No Banner"
                                  />
                                )}
                              </label>
                              <input
                                type="file"
                                className="form-control-file"
                                name="ImageURL"
                                id="ImageURL"
                                onChange={this.onFileChange}
                                style={{ display: "none" }}
                              />
                            </div>{" "}
                          </div>
                        </div>
                      </div>
                    </ModalBody>
                    <ModalFooter>
                      <Button
                        color="danger"
                        onClick={() => {
                          if (window.confirm("Delete the item?")) {
                            this.deleteBanner()
                          }
                        }}
                      >
                        Delete
                      </Button>
                      <Button color="secondary" onClick={this.showHideFormEdit}>
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Submit
                      </Button>
                    </ModalFooter>
                  </Form>
                </Modal>

                {/* Edit From Ends */}
                {/* ----------------------------------------------------------------------------------------------------- */}
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
