import React from "react";
import apiRequest from "../../hooks/HttpRequest";
import { Link } from "gatsby"
import axios from "axios";
import { navigate } from "gatsby"
import { saveUserOnSessionStorage, isLoggedIn } from "../../services/auth"


export default class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      PrimaryEmail: "",
      Password: "",
      errorMessage: "",
    };

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
  }

  handleOnChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false });
    } else {
      this.setState({ [e.target.name]: e.target.value, errorMessage: "" });
    }
  };
  handleUpdate = event => {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }
  handleSubmit = event => {
    event.preventDefault()
    var jsonLogin = {
      PrimaryEmail: this.state.PrimaryEmail,
      Password: this.state.Password,
    };

    axios
      .post(
        apiRequest.baseURL + "/Members/CheckLogin",
        jsonLogin,
        apiRequest.jsonHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          saveUserOnSessionStorage(response.data.User)
          navigate(`/members/profile`)
        }
        if (response.data.Status === "Error") {
          this.setState({
            errorMessage:
              "Invalid Credentials! Check Email and Password!!",
          }, () => {
            // console.log("Error " + this.state.errorMessage);
            alert(this.state.errorMessage);
          });
        }
      })
      .catch((e) => {
        // console.log(e)
      });

  }



  render() {
    if (isLoggedIn()) {
      navigate(`/members/profile`)
    }
    return (
      <>
        <div className="page-title-area">
          <div className="d-table">
            <div className="d-table-cell">
              <div className="container">
                <div className="page-title-content">
                  <h2>Login</h2>
                  <ul>
                    <li>
                      <Link to="/">Home</Link>
                    </li>
                    <li>Login</li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="login-section ptb-100">
          <div className="container">
            <div className="login-form">
              <div className="login-title">
                <h3>Welcome Back!</h3>
                <p>Please login to your account.</p>
              </div>
              <form method="post"
                onSubmit={event => {
                  this.handleSubmit(event)
                  navigate(`/members/profile`)
                }}>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="email"
                        name="PrimaryEmail"
                        className="form-control"
                        placeholder="Primary Email"
                        value={this.state.PrimaryEmail}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        type="password"
                        name="Password"
                        className="form-control"
                        placeholder="Password"
                        value={this.state.Password}
                        onChange={this.handleOnChange}
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="invalid-feedback">{this.state.errorMessage}</div>
                  </div>

                  <div className="col-12 col-lg-6">
                    <div className="mt-3">
                      <p className="forgot-password">
                        <Link to="/forgot-password">Forgot Password?</Link>
                      </p>
                      <p className="forgot-username">
                        <Link to="/forgot-username">Forgot Username?</Link>
                      </p>
                      <span>
                        Don't have account? <Link to="/register">Signup!</Link>
                      </span>
                    </div>
                  </div>
                  <div className="col-12 col-lg-6">
                    <div className="send-btn mt-0 text-right">
                      <button type="submit" className="default-btn">
                        Login Now
                      <span />
                      </button>
                    </div>

                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </>
    );
  }
}
