import React, { Component } from "react"
import axios from "axios"
import apiRequest from "../../hooks/HttpRequest"
import { Button, Form } from "reactstrap"
import { ToastContainer, toast } from "react-toastify"
import Sidebar from "../../layouts/Sidebar"
import "react-toastify/dist/ReactToastify.css"
import { isLoggedIn, getUser } from "../../services/auth"

export default class Myprofile extends Component {
  constructor(props) {
    super(props)

    this.state = {
      membersArray: [],
      fromAddOpen: false,
      formEditOpen: false,
      formAction: "Add",
      previewImage: "",
      alertMessage: "",
      LiveImageURL: null,
      MemberID: 0,
      FName: "",
      LName: "",
      PrimaryEmail: "",
      PrimaryPhone: "",
      SecondaryPhone: "",
      SecondaryEmail: "",
      SpouseName: "",
      SpouseEmail: "",
      FamilyMemberCount: "",
      MembershipType: "",
      MembershipFees: "",
      Password: "",
      PhotoURL: "",
      IsFromReadOnly: false,
      // //CreatedBy: sessionStorage.getItem("UserName"),
      //UpdatedBy: sessionStorage.getItem("UserName"),
    }
  }
  componentDidMount() {
    this.loadProfile()
  }

  //handles form inputs change events
  handleChange = (e) => {
    if (e.target.type === "checkbox") {
      this.setState({ [e.target.name]: e.target.checked ? true : false })
    } else {
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  //handles form file input change event
  onFileChange = (e) => {
    this.setState({
      PhotoURL: e.target.files[0],
      previewImage: URL.createObjectURL(e.target.files[0]),
    })
  }

  loadProfile() {
    var profileID = getUser().KyTSUser.MemberID
    // console.log(getUser().KyTSUser)
    // console.log(getUser().KyTSUser.MemberID)
    axios
      .get(
        apiRequest.baseURL + "Members/GetMember/" + profileID,
        apiRequest.jsonHeader
      )
      .then((response) => {
        // console.log(response.data.Member)
        this.setState({
          MemberID: response.data.Member.MemberID,
          FName: response.data.Member.FName,
          LName: response.data.Member.LName,
          PrimaryEmail: response.data.Member.PrimaryEmail,
          PrimaryPhone: response.data.Member.PrimaryPhone,
          SecondaryPhone: response.data.Member.SecondaryPhone,
          SecondaryEmail: response.data.Member.SecondaryEmail,
          SpouseName: response.data.Member.SpouseName,
          SpouseEmail: response.data.Member.SpouseEmail,
          FamilyMemberCount: response.data.Member.FamilyMemberCount,
          MembershipFees: response.data.Member.MembershipFees,
          MembershipType: response.data.Member.MembershipType,
          IsAdmin: response.data.Member.IsAdmin,
          IsCommitteeMember: response.data.Member.IsCommitteeMember,
          Password: response.data.Member.Password,
          PhotoURL: response.data.Member.PhotoURL,
          previewImage: response.data.Member.PhotoURL,
          formAction: "Edit",
        })
      })
      .catch((e) => {
        toast.error(e)
        // console.log(e)
      })
  }
  updateMember = (e) => {
    e.preventDefault()

    const formData = new FormData()

    formData.append("FName", this.state.FName)
    formData.append("LName", this.state.LName)
    formData.append("PrimaryEmail", this.state.PrimaryEmail)
    formData.append("PrimaryPhone", this.state.PrimaryPhone)
    formData.append("SecondaryPhone", this.state.SecondaryPhone)
    formData.append("SecondaryEmail", this.state.SecondaryEmail)
    formData.append("SpouseName", this.state.SpouseName)
    formData.append("SpouseEmail", this.state.SpouseEmail)
    formData.append("FamilyMemberCount", this.state.FamilyMemberCount)
    formData.append("MembershipType", this.state.MembershipType)
    formData.append("MembershipFees", this.state.MembershipFees)
    formData.append("CreatedBy", this.state.CreatedBy)
    formData.append("UpdatedBy", this.state.UpdatedBy)
    formData.append("IsAdmin", this.state.IsAdmin)
    formData.append("IsCommitteeMember", this.state.IsCommitteeMember)
    formData.append("PhotoURL", this.state.PhotoURL)

    return axios
      .put(
        apiRequest.baseURL + "/Members/UpdateMember/" + this.state.MemberID,
        formData,
        apiRequest.multipartFormHeader
      )
      .then((response) => {
        if (response.data.Status === "Success") {
          toast.success("Profile Updated !!")
          this.setState({
            alertMessage: "",
            LiveImageURL: null,
            MemberID: 0,
            FName: "",
            LName: "",
            PrimaryEmail: "",
            PrimaryPhone: "",
            SecondaryPhone: "",
            SecondaryEmail: "",
            SpouseName: "",
            SpouseEmail: "",
            FamilyMemberCount: "",
            MembershipType: "",
            Password: "",
            PhotoURL: "",
            IsAdmin: false,
            IsCommitteeMember: false,
            previewImage: "",
            formEditOpen: false,
            IsFromReadOnly: false,
          })

          this.loadGrid()
        } else {
          toast.error(response.data.Message)
        }
      })
      .catch((e) => {
        toast.error(e)
      })
  }
  render() {
    return (
      <>
        {isLoggedIn() && getUser().KyTSUser.IsAdmin ? (
          <Sidebar></Sidebar>
        ) : null}
        <div className="container">
          <div className="row">

            <div className="col-12 col-md-12">
              <div className="myprofile py-5">
                <Form onSubmit={this.updateMember}>
                  <h3>My Profile</h3>
                  <hr />
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          name="FName"
                          required
                          type="text"
                          className="form-control"
                          placeholder="First Name"
                          onChange={this.handleChange}
                          value={this.state.FName}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          type="text"
                          name="LName"
                          className="form-control"
                          placeholder="Last Name"
                          value={this.state.LName}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Primary Email (User Name)"
                          required
                          type="email"
                          name="PrimaryEmail"
                          className="form-control"
                          value={this.state.PrimaryEmail}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Primary Phone"
                          required
                          type="tel"
                          name="PrimaryPhone"
                          className="form-control"
                          value={this.state.PrimaryPhone}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Spouse Name"
                          type="text"
                          name="SpouseName"
                          className="form-control"
                          value={this.state.SpouseName}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Spouse Email"
                          type="text"
                          name="SpouseEmail"
                          className="form-control"
                          value={this.state.SpouseEmail}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Secondary Email"
                          type="text"
                          name="SecondaryEmail"
                          className="form-control"
                          value={this.state.SecondaryEmail}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Secondary Phone"
                          type="text"
                          name="SecondaryPhone"
                          className="form-control"
                          value={this.state.SecondaryPhone}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Family Members Count"
                          type="zip"
                          name="FamilyMemberCount"
                          pattern="[0-9]{1}"
                          className="form-control"
                          value={this.state.FamilyMemberCount}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <p>{this.state.MembershipType}</p>
                        {/* <select
                          placeholder="Membership Type"
                          required
                          type="zip"
                          name="MembershipType"
                          className="form-control"
                          onChange={this.handleChange}
                        >
                          <option value="">Select Membership</option>
                          <option value="15-Single Member">Single Member</option>
                          <option value="30-Family Member">Family Member</option>
                        </select> */}
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <div className="form-group">
                        <input
                          autoComplete="off"
                          placeholder="Password"
                          type="password"
                          name="Password"
                          className="form-control"
                          value={this.state.Password}
                          onChange={this.handleChange}
                          readOnly={!this.state.IsFromReadOnly}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4">
                      <label htmlFor="PhotoURL">Choose Photo</label>
                      <br />
                      <label htmlFor="ImageURL">
                        {this.state.previewImage !== "" ? (
                          <img
                            src={this.state.previewImage}
                            alt="Choose Member"
                            width="500"
                            height="400"
                          />
                        ) : (
                          <img
                            src={apiRequest.baseURL + "images/no-image.png"}
                            alt="No Member"
                          />
                        )}
                      </label>
                      <input
                        type="file"
                        className="form-control-file"
                        name="PhotoURL"
                        id="PhotoURL"
                        onChange={this.onFileChange}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                  {this.state.IsFromReadOnly === false &&
                    <Button type="button" color="primary" onClick={() => this.setState({ IsFromReadOnly: true })}>
                      Edit
                    </Button>
                  }
                  {this.state.IsFromReadOnly === true &&
                    <Button type="submit" color="primary">
                      Update Member
                    </Button>
                  }
                </Form>
                <ToastContainer />
              </div>
            </div>
          </div>
        </div>
      </>
    )
  }
}
