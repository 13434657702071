import React from "react"
import { Router } from "@reach/router"
import Layout from "../../components/layout"
import PrivateRoute from "../../components/privateRoute"
import Profile from "../../components/members/profile"
import Events from "../../components/members/events"
import Members from "../../components/members/members"
import Banners from "../../components/members/banners"
import Login from "../../components/members/login"
import Register from "../../components/members/register"
import Gallery from "../../components/members/gallery"
import Sponsor from "../../components/members/sponsors"
import FoodItem from "../../components/members/foodItems"
import OrderHistory from "../../components/members/orderHistory"
import RegisteredEvents from "../../components/members/registeredEvents"

const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/members/profile" component={Profile} />
      <PrivateRoute path="/members/events" component={Events} />
      <PrivateRoute path="/members/members" component={Members} />
      <PrivateRoute path="/members/banners" component={Banners} />
      <PrivateRoute path="/members/gallery" component={Gallery} />
      <PrivateRoute path="/members/index" component={Members} />
      <PrivateRoute path="/members/sponsors" component={Sponsor} />
      <PrivateRoute path="/members/food-items" component={FoodItem} />
      <PrivateRoute path="/members/order-history" component={OrderHistory} />
      <PrivateRoute path="/members/registeredEvents" component={RegisteredEvents} />
      <Login path="/login" />
      <Register path="/register" />
    </Router>
  </Layout>
)
export default App